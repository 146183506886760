var runOnPage = 'body.category';
var triggerInfiniteScrollElmt = '.pagerNav.end_pagi span.see_all_product';
var seeMoreContainerElmt = '.pagerNav.end_pagi';
var itemCountDisplayElmt = '.nbProdDisplayed';

if (document.body.classList.contains('mobile')) {
    // Load mobile version
    var runOnDevice = 'mobile';
    var headerWrapperElmt = '.banner_wrapper';
    var itemContainerElmt = '#scroll_items .list_item .item_container';
    var pushElmt = '#scroll_items .list_item > div';
    var resetElmt = '.menu_part.bottom a';
    var filteringFormContainerId = '#filters_menu';
    var filteringFormId = '#filters_form';
    var seeMoreElmt = '.pagerNav.end_pagi .displayAllProd';
    var deviceLoadDelay = 800;
    var scrollSpacer = 90;
    var sliderRangeElmt = '#slider-range';
    var sliderRangeMinTextElmt = '#slider-range span.ui-slider-handle:first';
    var sliderRangeMaxTextElmt = '#slider-range span.ui-slider-handle:last';
} else {
    // Load desktop version
    var runOnDevice = 'desktop';
    var headerWrapperElmt = '#site_head_wrap';
    var itemContainerElmt = '#list_item .item';
    var pushElmt = '#list_item .item';
    var resetElmt = '.main_menu_itm a';
    var filteringFormContainerId = '#trigger_filtre';
    var filteringFormId = '#filter_sticky';
    var seeMoreElmt = '.pagerNav.end_pagi .displayAllProd';
    var deviceLoadDelay = 500;
    var scrollSpacer = 10;
    var sliderRangeElmt = '.slider-range';
    var sliderRangeMinTextElmt = '.slider-range-container span.min';
    var sliderRangeMaxTextElmt = '.slider-range-container span.max';
}

// Check if element is in view port
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Activate/Desactivate infinite scroll
function initInfiniteScroll(activate = false) {
    if (activate == true) {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
        sessionStorage.setItem('infiniteScroll', true);
    } else {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
        sessionStorage.setItem('infiniteScroll', false);
    }
}

// Run when inifite scroll activated to check if first item or "See all" button is in view port to trigger next/previous page load
function handleScroll() {
    const isLoading = $('#is_loading').val();
    const maxItems = $('#totalElems').val();
    const productLoaded = $(itemContainerElmt + ':not(".push")').length;
    const button = $(triggerInfiniteScrollElmt);
    const nextBottomPage = parseInt($('#bottom_page').val()) + 1; // Get bottom page to load

    if (isLoading == 0 && productLoaded < maxItems) {
        // Continue infinite scroll if loading action complete and not all items loaded
        if (isInViewport(button)) {
            // If "See all" button visible, load next page
            paginationGoToPage(nextBottomPage, 'bottom');
        }
    } else if (productLoaded == maxItems) {
        // Stop inifinite scroll as all items loaded
        initInfiniteScroll(false);
        sessionStorage.setItem('infiniteScroll', true); // But keep session active for page reload or accessing page from back button
    }
}

// Goes to given page
// Works also with pager in shop districts
function paginationGoToPage(page = 1, scrollDirection) {
    const productLoaded = $(itemContainerElmt + ':not(".push")').length;
    const maxItems = $('#totalElems').val();

    const isLastPage = ((page-1) > Math.ceil(parseInt(productLoaded) / parseInt($('#nb_prod').val()))) ? true : false;
    const allProductLoaded = productLoaded == maxItems ? true : false;

    if (scrollDirection !== undefined && (isLastPage || allProductLoaded)) {
        // Stop infinite scroll if last page reached or all items loaded on page
        if (!allProductLoaded) {
            // All top pages not loaded, continue infinite scroll
            initInfiniteScroll(true);
        } else {
            // All pages loaded, stop infinite scroll
            initInfiniteScroll(false);
        }

        // Do not process further
        return false;
    }

    if (scrollDirection == 'top') {
        $('#top_page').val(page);
        initInfiniteScroll(true);
    } else if (scrollDirection == 'bottom') {
        $('#bottom_page').val(page);
        initInfiniteScroll(true);
    } else {
        // Both field uses same page reference
        $('#top_page').val(page);
        $('#bottom_page').val(page);

        // Remove infinite scroll eventLister when normal pagination
        initInfiniteScroll(false);

        setTimeout(() => {
            // Scroll to top
            if ($(runOnPage).length) {
                $(document).scrollTop(0);
            }
        }, 1000);
    }

    let nextPage = parseInt(page - 1);

    if (scrollDirection == 'top') {
        var fromScrollTop = true;
    } else if (scrollDirection == 'bottom') {
        var fromScrollBottom = true;
    }

    if (runOnDevice == 'mobile') {
        var type = document.getElementById('type_tri').value;
        var idObj = getCurrentIdRayon();

        if (from_type == 'product') {
            generateNewBlocProd(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop);
        } else {
            generateNewBlocSearch($('#nb_prod').val(), nextPage, null, fromScrollBottom, fromScrollTop);
        }
    } else {
        // Si on est sur la page de recherche
        if ($('#search_page').length > 0) {
            generateNewBlocSearch(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop, null);
        } else {
            generateNewBlocProd(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop);
        }
    }
}

function paginate(page = 1) {
    const $form = $(filteringFormId);

    // Check if the form exists
    if ($form.length == 0) {
        return;
    }

    var maxItems = parseFloat($form.find('#totalElems').val());
    var nb_prod = parseFloat($form.find('#nb_prod').val());
    var nb_total_page = Math.ceil(maxItems / nb_prod);

    $(seeMoreElmt).show();

    $.ajax({
        url: `${path_relative_root}ajax_reload_pagination.php`,
        type: 'GET',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page,
        },
        success: function (res) {
            if (res == '') {
                // Hide "See all" button as only one page available
                $(seeMoreElmt).css({ opacity: 0 });
            }
        },
        complete: function () {
            $(seeMoreContainerElmt).css({ opacity: 1 }); // Restore opacity for item autoscroll
            $(seeMoreElmt).css({ opacity: 1 }); // Restore opacity for see more button

            // Only for desktop
            if (runOnDevice == 'desktop') {
                initDailymotionVideos();
            } else if (runOnDevice == 'mobile') {
                $(itemContainerElmt).toggleClass('full', !$('#twoProducts').hasClass('actif'));
            }
        },
    });
}

function updateLoadedGauge() {
    var $form = $(filteringFormId);
    var total_element = parseInt($form.find("input[name='totalElems']").val());
    var bottom_page = parseInt($('#bottom_page').val());
    var nb_prod = parseInt($form.find('#nb_prod').val()) * bottom_page;
    var nb_prod_default = parseInt($form.find('#nb_prod_default').val());
    const productLoaded = $(itemContainerElmt + ':not(".push")').length;

    $(itemCountDisplayElmt).html(Translator.translate('load_more_products') + ' (' + productLoaded + ' / ' + total_element + ')');

    if (productLoaded >= total_element && total_element >= nb_prod_default && bottom_page > 1) {
        $(itemCountDisplayElmt).hide();
        $(seeMoreElmt).html('<span class="see_all_product preventDefaultClick" onclick="paginationGoToPage(1);">' + Translator.translate('load_nbDefaultProd') + ' ' + nb_prod_default + ' ' + Translator.translate('label_produit') + '</span>');
    } else if (nb_prod_default >= total_element && productLoaded>=total_element) {
        $(itemCountDisplayElmt).hide();
        $(seeMoreElmt).html('<span class="see_all_product" onclick=""></span>');
    } else if (bottom_page == 1) {
        // Restore initial pagination
        initInfiniteScroll(false);
        $(itemCountDisplayElmt).show();
        $(triggerInfiniteScrollElmt).removeClass('loading');
        $(seeMoreElmt).html('<span class="see_all_product">' + Translator.translate('see_all') + '</span>');
    }
}

function initAllFilters() {
    if (parseInt($('#nb_prod').val()) < parseInt($('#nb_prod_default').val())) {
        // Reset initial no. of products to default for first page load
        $('#nb_prod').val($('#nb_prod_default').val());
    }

    // Restore price filter
    var url = new URL(window.location.href);
    var triggerReload = false;

    if (url.searchParams) {
        var searchParams = url.searchParams;
    }

    if (searchParams) {
        if (searchParams.has('price')) {
            var min_price = $('input[name="initial_min_price"]').val();
            var max_price = $('input[name="initial_max_price"]').val();

            $(sliderRangeMinTextElmt).html(min_price);
            $(sliderRangeMaxTextElmt).html(max_price);

            $('input[name="price_min"]').val(min_price);
            $('input[name="price_max"]').val(max_price);

            var $slider = $(sliderRangeElmt);
            $slider.slider('values', 0, min_price);
            $slider.slider('values', 1, max_price);

            url.searchParams.delete('price');
        }

        if (searchParams.has('order')) {
            if (runOnDevice == 'desktop') {
                $('a.tri_price.actif').removeClass('actif');
                $('input#choice_tri').val(0);
            } else if (runOnDevice == 'mobile') {
                $('#wrapper_price_filtre input[name="tri"]:checked').trigger('click');
            }

            url.searchParams.delete('order');
            triggerReload = true;
        }

        window.history.replaceState({}, '', url.toString());
    }

    // Restore filter elements
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById(filteringFormId.replace('#', ''));
    var ctrl = Array.prototype.slice.call(form.elements, 0);
    const excludedNames = ['couleur', 'taille', 'eclat', 'charact', 'tri'];

    ctrl.forEach(function (elm) {
        if (elm.type === 'checkbox' || elm.type === 'radio') {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                if (!excludedNames.includes(elm.name)) {
                    elm.value = 0;
                }

                elm.checked = false;
            }
        }
    });

    // Restore filter menu
    if (runOnDevice == 'desktop') {
        setTimeout(() => {
            filterTrigger();
        }, 200);
    } else if (runOnDevice == 'mobile') {
        toggleMenu('filters', 'right');
    }

    setTimeout(() => {
        // Reset pagination variables
        fixItemCountOnPage();
        $('#totalElems').attr('data-origin', $('#totalElems').val());
    }, 1500);

    if (triggerReload) {
        paginationGoToPage.call(form, 1);
    }
}

function addClickTrigger(actualPage) {
    $(itemContainerElmt).each(function () {
        let dataAnchor = $(this).find('.anchor_ref').attr('id');
        let onclickValue = $(this).attr('onClick');

        if (dataAnchor !== undefined && (onclickValue === undefined || !$(this).attr("onClick").includes('saveItemData'))) {
            onclickValue = onclickValue === undefined ? '' : onclickValue;
            $(this).attr('onclick', "saveItemData('"+actualPage+"','"+dataAnchor+"');"+onclickValue);
        }
    });
}

function saveItemData(dataPage, dataAnchor) {
    updateUrlParameter('page', dataPage);
    updateUrlParameter('anchor', dataAnchor);
}

function updateUrlParameter(param, value) {
    // Create a URL object from the current window location
    var url = new URL(window.location.href);

    // Use URLSearchParams to modify the query parameters
    if (url.searchParams) {
        var searchParams = url.searchParams;
    }

    if (param == 'anchor') {
        // Construct new URL with anchor
        var newUrl = window.location.href.split('#')[0] + '#' + value;

        // Update the URL without reloading
        history.pushState(null, '', newUrl);
    } else if (param == 'page' && value == 1) {
        // Remove the specified parameter
        url.searchParams.delete(param);

        // Update the browser's URL without reloading the page
        window.history.replaceState({}, document.title, url);
    } else if (searchParams && searchParams.has(param) || sessionStorage.getItem('infiniteScroll') === 'true') {
        // Set or update the parameter
        searchParams.set(param, value);

        // Update the browser's address bar without reloading the page
        window.history.replaceState({}, '', url.toString());
    }
}

// Save infinite scroll last session state on page load
let initScrollToState = sessionStorage.getItem('infiniteScroll');

$(document).ready(function () {
    if ($(runOnPage).length) {
        const productLoaded = $(itemContainerElmt + ':not(".push")').length;
        const maxItems = parseInt($('#totalElems').val());

        if (productLoaded < maxItems) {
            // "See all" button must be visible when loading page for first time by default
            $(seeMoreElmt).fadeTo(100, 1);
        }

        // Load next page
        $(itemCountDisplayElmt).on('click', function () {
            $(this).addClass('loading');

            const productLoaded = $(itemContainerElmt + ':not(".push")').length;
            const nextPage = parseInt($('#bottom_page').val()) + 1;
            const maxItems = parseInt($('#totalElems').val());

            if (productLoaded < maxItems) {
                paginationGoToPage(nextPage, 'bottom');
                initInfiniteScroll(false);
                setTimeout(() => {
                    initInfiniteScroll(false);
                }, 1000);
            }
        });

        // Handle "See all" click to trigger infinite scroll
        $(seeMoreElmt).on('click', function () {
            if ($(triggerInfiniteScrollElmt + '.preventDefaultClick').length == 0) {
                $(triggerInfiniteScrollElmt).addClass('loading');
                $(itemCountDisplayElmt).hide();

                const productLoaded = $(itemContainerElmt+':not(".push")').length;
                const nextPage = parseInt($('#bottom_page').val()) + 1;
                const maxItems = parseInt($('#totalElems').val());

                if (productLoaded < maxItems) {
                    paginationGoToPage(nextPage, 'bottom');
                }
            }
        });

        // Store clicked product page reference
        addClickTrigger($('#bottom_page').val());

        fixItemCountOnPage();

        // Restore order field value after page reload
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('order')) {
            if (urlParams.get('order') == 'asc') {
                if (runOnDevice == 'desktop') {
                    $('#choice_tri').val(1);
                    $('a[data-tri="1"]').addClass('actif');
                } else if (runOnDevice == 'mobile') {
                    $('input[name="tri"][value="1"]').prop('checked', true);
                    $('input[name="tri"][value="2"]').prop('checked', false);
                }
            } else if (urlParams.get('order') == 'desc') {
                if (runOnDevice == 'desktop') {
                    $('#choice_tri').val(2);
                    $('a[data-tri="2"]').addClass('actif');
                } else if (runOnDevice == 'mobile') {
                    $('input[name="tri"][value="1"]').prop('checked', false);
                    $('input[name="tri"][value="2"]').prop('checked', true);
                }
            } else if (runOnDevice == 'desktop' && urlParams.get('order') == 'new') {
                $('#choice_tri').val(5);
                $('a[data-tri="5"]').addClass('actif');
            }
        }

        setTimeout(() => {
            var anchor = window.location.hash;

            if (anchor) {
                // Smooth scroll to anchor present in URL
                smoothScrollToElement(anchor);
            } else {
                // On page load, if not page 1, scroll to corresponding page
                var currentUrl = window.location.href;
                var previousUrl = sessionStorage.getItem('watchURL');

                if (previousUrl !== currentUrl && urlParams.has('page') && parseInt(urlParams.get('page')) > 1) {
                    var goToElmt = (parseInt(urlParams.get('page')) - 1) * parseInt($("#nb_prod").val());

                    setTimeout(() => {
                        smoothScrollToElement (itemContainerElmt + '[data-pi="' + goToElmt + '"]');
                    }, deviceLoadDelay);
                }
            }
        }, deviceLoadDelay);

        // Restore infinite scroll if previously present
        if (initScrollToState === 'true') {
            setTimeout(() => {
                initInfiniteScroll(true);

                const productLoaded = $(itemContainerElmt + ':not(".push")').length;
                const maxItems = parseInt($('#totalElems').val());

                if (productLoaded < maxItems) {
                    $(triggerInfiniteScrollElmt).addClass('loading');
                    $(itemCountDisplayElmt).hide();
                }
            }, 1000);
        }
    }

    sessionStorage.removeItem('watchURL');

    setTimeout(() => {
        $(resetElmt).on('click touchstart', function () {
            if (!$(this).attr('onClick')) {
                // Disable infinite scroll if page 1 is loaded else leave last choice on
                initInfiniteScroll(false);
            }
        });
    }, deviceLoadDelay);
});

$(window).on('beforeunload', function () {
    sessionStorage.setItem('watchURL', window.location.href);
});

function smoothScrollToElement(anchor) {
    var targetElement = $(anchor);

    if (targetElement.length) {
        var headerHeight = $(headerWrapperElmt).outerHeight(); // Adjust selector as needed for the header
        var targetPosition = $(targetElement).offset().top - (headerHeight + scrollSpacer); // Calculate target position

        // Remove anchor from the URL after scrolling if present
        history.replaceState(null, null, ' '); // This removes the hash

        $('html, body').animate({
                scrollTop: targetPosition
        }, 1300); // Duration of the scroll
    }
}

function fixItemCountOnPage() {
    var max_loop = parseInt($('#nb_prod_default').val());
    var item_count = 0;
    var push_count = 0;

    $(pushElmt).each(function (index, elmt) {
        if (index < max_loop) {
            if ((runOnDevice == 'desktop' && $(elmt).hasClass("push") && $(elmt).is(':visible')) || 
                (runOnDevice == 'mobile' && $(elmt).hasClass("show_push_multi_product"))) {
                push_count++;
            } else {
                item_count++;
            }
        }
    });

    $('#push_count').val(push_count);
    $('#nb_prod').val(item_count);
}
